<template>
  <div>
    <div class="container post-breadcrumb-spacer">
      <div class="row justify-content-center">
        <div class="">
          <h2 class="text-center pb-3">
            Pick a lesson plan
          </h2>
        </div>
      </div>
    </div>
    <div class="container">
      <course-list v-if="software.isCourseListLoaded" :software="software" :is-library="false"
                   :on-click="selectPlan" />
      <sp-loader v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import CourseList from '@/components/CourseList';
import SpLoader from '@/components/SPLoader';

export default {
  name: 'LessonPlanSelect',
  components: {
    'course-list': CourseList,
    'sp-loader': SpLoader,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['lessonBuilder/getSubject']) {
        next({name: 'LessonPlanSubject'});
      } else {
        next();
      }
    });
  },
  computed: {
    subject() {
      return this.$store.getters['lessonBuilder/getSubject'];
    },
    software() {
      return this.$store.getters['software/getSoftwareBySlug'](
        this.subject.slug
      );
    },
    courses() {
      if (this.software) {
        return this.software.courses;
      } else {
        return null;
      }
    }
  },
  created() {
    this.loadSoftware(this.subject.slug);
  },
  methods: {
    /**
     * sr 4/6/18
     * Load software object from the api
     */
    loadSoftware(slug) {
      this.isLoading = true;
      // if the full software object hasn't been retrieved, retrieve it
      return new Promise((resolve, reject) => {
        if (this.software && this.software.isCourseListLoaded) {
          // already loaded, don't load again
          this.isLoading = false;
          resolve();
        } else {
          // load the software
          this.$store.dispatch('software/loadSoftwareBySlug', slug).then(
            (response) => {
              this.isLoading = false;
              resolve();
            },
            (error) => {
              // If software is not found return to previous step with error message
              console.log(error);
              this.isLoading = false;
              reject(error);
            }
          );
        }
      });
    },
    selectPlan(plan) {
      this.$store.commit('lessonBuilder/setPlan', plan);
      this.$router.push({ name: 'LessonPlanPreview' }).catch(err => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
